import React from 'react';
import { normalizeTwoWords } from '../shared/utils/stringUtilities';

const drawSocialIcons = (type: boolean, value: any) => {
    if (type) {
        if (value.includes('1')) {
            return value.replace('1', '');
        } else {
            return value.concat('', '1');
        }
    }
    return value;
};

const renderMediaIcon = (red) => {
    switch (red) {
        case 'tiktok':
            return (
                <svg
                    className={`gtm${normalizeTwoWords(red)}`}
                    xmlns="http://www.w3.org/2000/svg"
                    width="45"
                    height="45"
                >
                    <defs>
                        <clipPath id="clip-path-t">
                            <path
                                className="cls-1"
                                d="M22.49,45a22.46,22.46,0,1,1,8.76-1.77A22.5,22.5,0,0,1,22.49,45Zm0-43.65A21.05,21.05,0,1,0,30.72,3,20.94,20.94,0,0,0,22.49,1.35Z"
                            />
                        </clipPath>
                    </defs>
                    <g id="Capa_1" data-name="Capa 1">
                        <path
                            id="Combined_Shape-7"
                            data-name="Combined Shape-7"
                            className="cls-1"
                            d="M22.49,45a22.46,22.46,0,1,1,8.76-1.77A22.5,22.5,0,0,1,22.49,45Zm0-43.65A21.05,21.05,0,1,0,30.72,3,20.94,20.94,0,0,0,22.49,1.35Z"
                        />
                        <path
                            className="cls-1"
                            d="M22.49,45a22.46,22.46,0,1,1,8.76-1.77A22.5,22.5,0,0,1,22.49,45Zm0-43.65A21.05,21.05,0,1,0,30.72,3,20.94,20.94,0,0,0,22.49,1.35Z"
                        />
                        <g className="cls-2-t">
                            <g
                                id="Enmascarar_grupo_11"
                                data-name="Enmascarar grupo 11"
                            >
                                <g
                                    id="color_iconos_blue-gray-2"
                                    data-name="color iconos blue-gray-2"
                                >
                                    <rect
                                        id="black-2"
                                        className="cls-1"
                                        x="-0.23"
                                        width="45"
                                        height="45"
                                    />
                                </g>
                            </g>
                        </g>
                    </g>
                    <g id="Objects">
                        <path
                            className="cls-1"
                            d="M23.41,11.25h3.8c.37,3.25,2.24,5,5.41,5.41v3.73a8.93,8.93,0,0,1-5.3-1.62c0,.27-.05.46-.05.65,0,2.38,0,4.75,0,7.12a7.11,7.11,0,0,1-7,7.2c-3.32.22-6.65-2.68-7.17-6.24a7.1,7.1,0,0,1,7.25-8c.23,0,.47.06.73.09v3.78c-.2,0-.4,0-.6,0a3.23,3.23,0,1,0-.51,6.44,3.21,3.21,0,0,0,3.48-3.25c0-4.74,0-9.49,0-14.23Z"
                        />
                    </g>
                </svg>
            );
        case 'spotify':
            return (
                <svg
                    className={`gtm${normalizeTwoWords(red)}`}
                    xmlns="http://www.w3.org/2000/svg"
                    width="45"
                    height="45"
                >
                    <defs>
                        <clipPath id="clip-path-s">
                            <path
                                className="cls-1"
                                d="M22.49,45a22.46,22.46,0,1,1,8.76-1.77A22.5,22.5,0,0,1,22.49,45Zm0-43.65A21.05,21.05,0,1,0,30.72,3,20.94,20.94,0,0,0,22.49,1.35Z"
                            />
                        </clipPath>
                    </defs>
                    <g id="Capa_1" data-name="Capa 1">
                        <path
                            id="Combined_Shape-7"
                            data-name="Combined Shape-7"
                            className="cls-1"
                            d="M22.49,45a22.46,22.46,0,1,1,8.76-1.77A22.5,22.5,0,0,1,22.49,45Zm0-43.65A21.05,21.05,0,1,0,30.72,3,20.94,20.94,0,0,0,22.49,1.35Z"
                        />
                        <path
                            className="cls-1"
                            d="M22.49,45a22.46,22.46,0,1,1,8.76-1.77A22.5,22.5,0,0,1,22.49,45Zm0-43.65A21.05,21.05,0,1,0,30.72,3,20.94,20.94,0,0,0,22.49,1.35Z"
                        />
                        <g className="cls-2-s">
                            <g
                                id="Enmascarar_grupo_11"
                                data-name="Enmascarar grupo 11"
                            >
                                <g
                                    id="color_iconos_blue-gray-2"
                                    data-name="color iconos blue-gray-2"
                                >
                                    <rect
                                        id="black-2"
                                        className="cls-1"
                                        x="-0.23"
                                        width="45"
                                        height="45"
                                    />
                                </g>
                            </g>
                        </g>
                    </g>
                    <g id="Modo_de_aislamiento" data-name="Modo de aislamiento">
                        <circle
                            className="cls-1"
                            cx="22.45"
                            cy="22.48"
                            r="11.23"
                        />
                        <path
                            className="cls-3"
                            d="M27,27.83a.76.76,0,0,1-.38-.11c-4.42-2.66-9.67-1.12-9.72-1.1a.75.75,0,0,1-.92-.49.73.73,0,0,1,.49-.92c.24-.08,5.91-1.76,10.91,1.25a.73.73,0,0,1,.25,1A.73.73,0,0,1,27,27.83Z"
                        />
                        <path
                            className="cls-3"
                            d="M28.23,24.8a.88.88,0,0,1-.43-.12,14.79,14.79,0,0,0-11.21-1.31.88.88,0,0,1-1.11-.55.86.86,0,0,1,.54-1.1,16.35,16.35,0,0,1,12.65,1.45.87.87,0,0,1-.44,1.63Z"
                        />
                        <path
                            className="cls-3"
                            d="M29.61,21.36a1.07,1.07,0,0,1-.49-.12,18.68,18.68,0,0,0-13.06-1.33,1.07,1.07,0,0,1-.64-2.05,20.65,20.65,0,0,1,14.69,1.48,1.07,1.07,0,0,1-.5,2Z"
                        />
                    </g>
                </svg>
            );
        case 'twitter':
            return (
                <svg
                    className={`gtm${normalizeTwoWords(red)}`}
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    width="45px"
                    height="45px"
                    viewBox="0 0 45 45"
                    version="1.1"
                >
                    <g id="surface1">
                        <path
                            className="cls-x-twitter-1"
                            d="M 10.097656 11.054688 L 19.726562 23.660156 L 10 33.925781 L 12.195312 33.925781 L 20.679688 24.964844 L 27.542969 33.925781 L 34.980469 33.925781 L 24.777344 20.578125 L 33.835938 11.054688 L 31.644531 11.054688 L 23.824219 19.363281 L 17.53125 11.054688 Z M 13.242188 12.644531 L 16.675781 12.644531 L 31.738281 32.339844 L 28.304688 32.339844 Z M 13.242188 12.644531 "
                        />
                        <path
                            className="cls-x-twitter-2"
                            d="M 46.101944 24.1 C 46.101944 36.52766 36.026875 46.599504 23.6 46.599504 C 11.173125 46.599504 1.098056 36.52766 1.098056 24.1 C 1.098056 11.67234 11.173125 1.600496 23.6 1.600496 C 36.026875 1.600496 46.101944 11.67234 46.101944 24.1 Z M 46.101944 24.1 "
                            transform="matrix(0.95339,0,0,0.933528,0,0.00198623)"
                        />
                    </g>
                </svg>
            );
        default:
            return (
                <i
                    className={`gtm${normalizeTwoWords(red)} ${drawSocialIcons(
                        false,
                        red,
                    )}`}
                />
            );
    }
};

export default renderMediaIcon;
