import React, { useEffect, useState } from 'react';
import { Box, Container, Grid } from '@material-ui/core';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import renderMediaIcon from '../../../utils/renderMediaIcon';
import logoMagenta from '../../../assets/images/logos/comfama/logo-magenta.svg';
import styles from './footer.module.scss';

const Footer = () => {
    const [footerData, setFooterData] = useState<any>({
        redesSociales: [],
        seccionColumnas: [],
        seccionLogos: [],
        logoVigilado: '',
        copyright: '',
    });
    useEffect(() => {
        const _get = fetch('/components/footer-info.json');
        _get.then(async (resp) => {
            const footerInfo = await resp.json();
            setFooterData({
                redesSociales: footerInfo[0].redesSociales,
                seccionColumnas: separarPorHR(
                    documentToReactComponents(
                        footerInfo[0].seccionColumnas.json,
                        options,
                    ),
                ),
                seccionLogos: documentToReactComponents(
                    footerInfo[0].seccionLogos.json,
                    options,
                ),
                logoVigilado: footerInfo[0].logoVigilado.file.url,
                copyright: footerInfo[0].copyright,
            });
        });
    }, []);

    const renderIcon = (red, index) => {
        if (red.icon.includes('icon-')) {
            return (
                <a href={red.link} aria-label={red.titulo} key={index}>
                    <i className={red.icon} />
                </a>
            );
        } else {
            return (
                <a href={red.link} aria-label={red.titulo} key={index}>
                    {renderMediaIcon(red.icon)}
                </a>
            );
        }
    };

    function separarPorHR(array) {
        const grupos: any = [];
        let grupoActual: any = [];
        array.forEach((objeto) => {
            if (objeto.type === 'hr') {
                if (grupoActual.length > 0) {
                    grupos.push(grupoActual);
                }
                grupoActual = [];
            } else {
                grupoActual.push(objeto);
            }
        });
        if (grupoActual.length > 0) {
            grupos.push(grupoActual);
        }
        return grupos;
    }

    const options = {
        renderMark: {
            [MARKS.ITALIC]: (text) => (
                <span className={styles.o_footer__version}>{text}</span>
            ),
            [MARKS.BOLD]: (text) => (
                <p className={styles.o_footer__title}>{text}</p>
            ),
            [MARKS.UNDERLINE]: (text) => (
                <p className={styles.o_footer__magenta}>{text}</p>
            ),
        },
        renderNode: {
            [BLOCKS.EMBEDDED_ASSET]: (node) => (
                <img
                    className={styles.o_footer__logo}
                    src={node?.data?.target?.fields?.file['en-US']?.url}
                    alt={
                        node?.data?.target?.fields?.file[
                            'en-US'
                        ].fileName.split('.')[0]
                    }
                />
            ),
        },
    };

    return footerData ? (
        <Box component="section" id="footer" className={styles.o_footer}>
            <Container className={styles.o_footer__container}>
                <Grid
                    container
                    lg={12}
                    md={10}
                    className={styles.o_footer__grid}
                >
                    {footerData.redesSociales && (
                        <Box className={styles.o_footer__siguenos}>
                            <span>Síguenos</span>
                            <Box className={styles.o_footer__rrss}>
                                {footerData.redesSociales.map((red, index) =>
                                    renderIcon(red, index),
                                )}
                            </Box>
                        </Box>
                    )}
                    {footerData.seccionColumnas && (
                        <Box className={styles.o_footer__cols}>
                            {footerData.seccionColumnas.map((col, index) => (
                                <div
                                    className={styles.o_footer__col}
                                    key={index}
                                >
                                    {col}
                                </div>
                            ))}
                        </Box>
                    )}
                    {footerData.seccionLogos && (
                        <Box className={styles.o_footer__logos}>
                            <a
                                href="https://www.comfama.com"
                                target="_self"
                                rel="referrer follow"
                                className={styles.o_footer__logocaja}
                                aria-label="Ir a Comfama.com"
                            >
                                <img src={logoMagenta} alt="Logo Comfama" />
                            </a>
                            <p>{footerData.seccionLogos}</p>
                            {footerData.logoVigilado && (
                                <a
                                    href="https://www.ssf.gov.co/"
                                    target="_blank"
                                    rel="noreferrer follow"
                                >
                                    <img
                                        className={styles.o_footer__vigilado}
                                        src={footerData.logoVigilado}
                                        alt="Logo Vigilado supersubsidio"
                                    />
                                </a>
                            )}
                        </Box>
                    )}
                </Grid>
            </Container>
            {footerData.copyright && (
                <Box className={styles.o_footer__copyright}>
                    {footerData.copyright}
                </Box>
            )}
        </Box>
    ) : null;
};

export default Footer;
