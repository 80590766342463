import React from 'react';
import SEO from '../shared/seo';
import { ReferenciaContenido } from '../interfaces/ReferenciaContenido';
import LibraryComponents from '../components/templates/library-components/libraryComponents';
import useScreenSize from '../hooks/useScreenSize';
import Header from '../components/organisms/header/header';
import TopbarMenu from '../components/molecules/topbar-menu/topbarMenu';
import Footer from '../components/organisms/footer/footer';
import GlobalContext from '../contexts/globalContext';
import useLoginGigya from '../shared/hooks/useLoginGigya';
import PizzaButton from '../components/molecules/pizza-button/pizzaButton';
import './../styles/index.scss';

const IndexPage = (props: any | undefined) => {
    const gigyaContext = useLoginGigya();
    const screenSize = useScreenSize();
    const referenciaContenido: ReferenciaContenido[] =
        props?.pageContext.allContentfulHome.nodes[0].referenciaContenido;
    const metaTitle: string =
        props?.pageContext.allContentfulHome.nodes[0].metaTitulo;
    const metaDescription: any =
        props?.pageContext.allContentfulHome.nodes[0].metaDescripcion;

    const context = {
        gigyaContext: { ...gigyaContext },
    };

    return (
        <GlobalContext.Provider value={context}>
            <SEO
                title={
                    metaTitle ||
                    'Caja de compensación familiar de Antioquia - Comfama'
                }
                description={
                    metaDescription
                        ? metaDescription.json.content[0].content[0].value
                        : 'En Comfama, Caja de Compensación Familiar de Antioquia, trabajamos por una clase media antioqueña consciente, productiva, libre y feliz.'
                }
                lang={'es'}
                fbId={process.env.FACEBOOK_APP_ID_HOME}
            />
            <Header />
            {screenSize > 968 && <TopbarMenu />}
            <main>
                {referenciaContenido.map(
                    (value: ReferenciaContenido, index: number) => (
                        <LibraryComponents
                            key={`section-${index}`}
                            content={value}
                            categoryName={'Home'}
                        />
                    ),
                )}
            </main>
            <Footer />
            <PizzaButton />
        </GlobalContext.Provider>
    );
};

export default IndexPage;
